import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import Axios from "axios"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import LoginFooter from "./loginFooter"
import TermsAndConditions from "./termsAndConditions"
import SocialMediaLogin from "./SocialMediaLogin"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-icons/font/bootstrap-icons.css"
import "../components/login.css"
import "@fontsource/noto-sans"

const Signup = () => {
  const [errorMessage, setErrorMessage] = useState(""),
    [values, setValues] = useState({
      loading: false,
    })
  const { loading } = values

  const handleSignup = e => {
    e.preventDefault()

    setErrorMessage("")

    const formData = new FormData(e.target)
    const name = formData.get("name")
    const email = formData.get("email")
    const referred_by = formData.get("referred_by")

    if (name && email && referred_by) {
      setValues({
        ...values,
        loading: true,
      })
      Axios.post(process.env.GATSBY_API_URL + "/signup/", { name, email, referred_by })
        .then(response => {
          if (response.data.status) {
            navigate("/reset-password/request/?chat=newuserpwreset")
          }
          return response.data
        })
        .catch(error => {
          setErrorMessage(error.response.data.message)
          setValues({
            ...values,
            loading: false,
          })
        })
    }
  }

  return (
    <React.Fragment>
      <section className="loginSection">
        <div className="loginContainer">
          <div className="row">
            <div className="col-md-12 col-xs-12 text-center">
              <SocialMediaLogin type="signup" />
              <div className="signup-or-separator">
                <span className="signup-or-separator--text h6">or</span>
                <hr />
              </div>
              <form onSubmit={handleSignup} id="loginForm">
                <div className="input-group mb-3">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Your Name</Tooltip>}>
                    <span
                      className="input-group-text"
                      data-bs-toggle="tooltip"
                      data-placement="bottom"
                      title=""
                    >
                      <i className="bi bi-person-fill" />
                    </span>
                  </OverlayTrigger>
                  <input
                    id="username"
                    className="form-control"
                    type="text"
                    tabIndex="1"
                    name="name"
                    placeholder="Name"
                    required
                    autoFocus
                  />
                </div>
                <div className="input-group mb-3">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Email</Tooltip>}>
                    <span className="input-group-text">@</span>
                  </OverlayTrigger>
                  <input
                    id="username"
                    className="form-control formControlInput"
                    type="email"
                    tabIndex="1"
                    name="email"
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="input-group mb-3">
                  <OverlayTrigger placement="top" overlay={<Tooltip>Referred By</Tooltip>}>
                    <span className="input-group-text">@</span>
                  </OverlayTrigger>
                  <input
                    id="referred_by"
                    className="form-control formControlInput"
                    type="email"
                    tabIndex="1"
                    name="referred_by"
                    placeholder="Referred By"
                    required
                  />
                </div>
                {errorMessage && <div className="text-danger">{errorMessage}</div>}

                <div className="mb-3 text-start form-inline communication-container">
                  <div className="from-check">
                    <input
                      type="checkbox"
                      name="Communication"
                      id="Communication"
                      className="css-checkbox"
                      defaultChecked={true}
                      value="1"
                    />
                    <label htmlFor="Communication" className="css-checkbox-label radGroup2">
                      <small>
                        Communication from SwiftCloud is ok
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip>
                              Email, SMS, or Phone Calls; you can opt-out anytime on any channel. Required in
                              order to access your product(s).
                            </Tooltip>
                          }
                        >
                          <i className="bi bi-question-circle-fill mx-1" />
                        </OverlayTrigger>
                      </small>
                    </label>
                  </div>
                </div>
                <div className="text-center">
                  <p className="termsCondition">
                    By signing up or logging in, you are agreeing to our fascinating{" "}
                    <a className="dashedLinked" href="https://swiftcloud.ai/terms" target="_blank">
                      terms of use
                    </a>{" "}
                    &amp;{" "}
                    <a className="dashedLinked" href="https://swiftcloud.ai/privacy" target="_blank">
                      privacy policy
                    </a>
                    .
                  </p>
                </div>
                <div className="mb-3">
                  <button
                    type="submit"
                    disabled={loading}
                    id="loginModalButton"
                    className="btn btn-primary w-100"
                  >
                    <i className="bi bi-check2" /> Create Account{" "}
                    {loading && <i className="spinner-border spinner-border-sm" />}
                  </button>
                </div>
              </form>
              <hr />
              <div className="text-center">
                <p>
                  Already Hooked Up?{" "}
                  <Link to="/login" className="btn btn-secondary">
                    <i className="bi bi-arrow-right-circle" /> Login
                  </Link>
                </p>
              </div>
              <TermsAndConditions />
            </div>
          </div>
        </div>
      </section>
      <LoginFooter />
    </React.Fragment>
  )
}

export default Signup
